require('../assets/js/GMap.js');
require('../assets/js/Products.js');
require('../assets/js/SingleProduct.js');
require('../assets/js/Home.js');

jQuery(document).ready(function () {
    initGMap();
});

function initGMap() {
    console.log('Init GMAP');
    let mapBlock = document.getElementById('gmap');

    if (mapBlock) {
        new GMap({
            'apiRoute': varObject.apiLocationRoute,
            'nonce': varObject.nonce,
            'pathToTheme': varObject.pathToTheme,
            'notFoundMapResultsMessage': varObject.notFoundMapResultsMessage
        });
    }
}

window.initGMap = initGMap;