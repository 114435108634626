(function ($) {
    let destinationCountrySelect;
    let requestData = {};

    function Home(options) {
        if (!options) {
            throw new Error('No options passed to Home constructor');
        } else if (!options.apiRoute) {
            throw new Error('No apiRoute option passed to Home constructor');
        } else if (!options.nonce) {
            throw new Error('No Nonce option passed to Home constructor');
        }

        this.options = $.extend({}, Home.defaults, options);
        this.setup();
    }

    /**
     * Prepare Home
     */
    Home.prototype.setup = function () {
        let self = this;
        console.log('Home initialization');

        //Change destination logic
        destinationCountrySelect = $('#destination-country');
        $('#destination-country-save').on('click', function (event) {
            self.changeDestinationCountry(event, self);
        });
    }

    Home.prototype.changeDestinationCountry = function (event, self) {
        event.preventDefault();
        requestData.destinationCountry = destinationCountrySelect.find('option:selected').val();
        self.sendRequest(self);
    }

    /**
     * Request products from the server
     */
    Home.prototype.sendRequest = function (self) {
        console.log(requestData);
        jQuery.ajax({
            type: 'POST',
            url: self.options.apiRoute,
            data: requestData,
            dataType: "json",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
            },
            success: function (response) {
                self.parseResponse(response, self);
            },
            error: function (data) {
                console.log(data);
            }
        });
    }

    Home.prototype.parseResponse = function (response) {
        console.log(response);

        if (!response.status) {
            console.log('Response error');
            return;
        }

        if (response.reload) { //Destination country changed
            window.location.reload();
        }
    }

    window.Home = Home;

})(jQuery);