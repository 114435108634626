(function ($) {

        function SingleProduct(options) {
            console.log('SingleProduct initialization');
            if (!options) {
                throw new Error('No options passed to SingleProduct constructor');
            } else if (!options.quantityIncreaseButtonID) {
                throw new Error('No quantityIncreaseButtonID option passed to SingleProduct constructor');
            } else if (!options.quantityReduceButtonID) {
                throw new Error('No quantityReduceButtonID option passed to SingleProduct constructor');
            }

            this.options = $.extend({}, Products.defaults, options);
            this.setupQuantity();
            this.addScrollToReviewTab();

            $('.submit').on('click', function (event) {
                console.log($('#rating option:checked').val());

                if (!$('#rating option:checked').val()) {
                    $('.customer-review-star .stars').append('<div id="stars_required">Rating is required</div>');
                } else {
                    if ($('#stars_required').length) {
                        $('#stars_required').remove();
                    }
                }
            });
        }

        /**
         * Increase or reduce quantity by click on buttonl
         */
        SingleProduct.prototype.setupQuantity = function () {
            try {
                let qtyInput = $('.add-basket-area input.qty');
                if (qtyInput.length) {
                    $('#' + this.options.quantityIncreaseButtonID).on('click', function (event) {
                        let value = parseInt(qtyInput.val());
                        qtyInput.val(value + 1);
                    });

                    $('#' + this.options.quantityReduceButtonID).on('click', function (event) {
                        let value = parseInt(qtyInput.val());
                        if (value > 1) {
                            qtyInput.val(value - 1);
                        }
                    });
                }
            } catch (e) {
                console.log(e.message);
            }
        }

        /**
         * Fix trouble with scroll to reviews tab after click by "all reviews" link
         */
        SingleProduct.prototype.addScrollToReviewTab = function () {

            $('.woocommerce-review-link').click(function (event) {
                event.preventDefault();

                $('.product-tab-outer a.active').removeClass('active');
                $('.product-tab-outer #reviews-tab').addClass('active');
                $('.product-tab-outer div.active').removeClass('active').removeClass('show');
                $('.product-tab-outer #reviews-block').addClass('active').addClass('show');

                $('#tab-title-reviews').click();
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#tab-title-reviews").offset().top
                }, 'fast');
            });

            if (location.href.indexOf("#") !== -1) {
                $('.product-tab-outer a.active').removeClass('active');
                $('.product-tab-outer #reviews-tab').addClass('active');
                $('.product-tab-outer div.active').removeClass('active').removeClass('show');
                $('.product-tab-outer #reviews-block').addClass('active').addClass('show');

                $('#tab-title-reviews').click();
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(location.hash).offset().top - $('.header').height()
                }, 'fast');
            }

        }

        window.SingleProduct = SingleProduct;

    }
)
(jQuery);