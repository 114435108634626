(function ($) {
    let categoryFilter;
    let productsFilter;
    let destinationCountrySelect;
    let requestData = {};

    function Products(options) {
        if (!options) {
            throw new Error('No options passed to Products constructor');
        } else if (!options.paginationBlockID) {
            throw new Error('No paginationBlockID option passed to Products constructor');
        } else if (!options.apiRoute) {
            throw new Error('No apiRoute option passed to Products constructor');
        } else if (!options.nonce) {
            throw new Error('No Nonce option passed to Products constructor');
        } else if (!options.categoryTermId) {
            throw new Error('No categoryTermId option passed to Products constructor');
        } else if (!options.hasOwnProperty('search')) {
            throw new Error('No search option passed to Products constructor');
        }

        this.options = $.extend({}, Products.defaults, options);
        this.setup();
    }

    /**
     * Prepare Products
     */
    Products.prototype.setup = function () {
        let self = this;
        let body = $('body');
        console.log('Products initialization');

        //Set category term
        if (self.options.categoryTermId) {
            requestData.category = parseInt(self.options.categoryTermId);
        }

        //Set search
        if (self.options.search) {
            requestData.search = self.options.search;
        }

        //Pagination logic
        if ($('#' + this.options.paginationBlockID).length) {
            body.on('click', '#' + this.options.paginationBlockID + ' .page-number', function (event) {
                self.changePage(event, self);
            });
            body.on('click', '#' + this.options.paginationBlockID + ' #product_pagination_prev', function (event) {
                self.moveToPreviousPage(event, self);
            });
            body.on('click', '#' + this.options.paginationBlockID + ' #product_pagination_next', function (event) {
                self.moveToNextPage(event, self);
            });
        }

        //Category logic
        categoryFilter = $('#filter-products-by-category');
        categoryFilter.on('change', function (event) {
            self.changeCategory(event, self);
        });

        //Filter logic
        productsFilter = $('#orderby');
        productsFilter.on('change', function (event) {
            self.applyFilter(event, self);
        });

        //Change destination logic
        destinationCountrySelect = $('#destination-country');
        $('#destination-country-save').on('click', function (event) {
            self.changeDestinationCountry(event, self);
        });
    }

    /**
     * Change products page
     *
     * @param event
     * @param self
     */
    Products.prototype.changePage = function (event, self) {
        event.preventDefault();
        if ($(self).hasClass('disabled')) {
            return;
        }

        requestData.page = parseInt($(event.target).data('page-id'));
        self.sendRequest(self);
    }

    /**
     * Move to previous page
     *
     * @param event
     * @param self
     */
    Products.prototype.moveToPreviousPage = function (event, self) {
        event.preventDefault();
        if ($(event.target).hasClass('disabled')) {
            return;
        }

        let previousPage = parseInt(self.getActivePage()) - 1;
        if (previousPage < 1) {
            throw new Error('Previous page must have number 1 or higher');
        }
        console.log(previousPage);
        requestData.page = previousPage;
        self.sendRequest(self);
    }

    /**
     * Move to next page
     *
     * @param event
     * @param self
     */
    Products.prototype.moveToNextPage = function (event, self) {
        event.preventDefault();
        if ($(event.target).hasClass('disabled')) {
            return;
        }

        //TODO: Add validation if page number more then last page
        requestData.page = parseInt(self.getActivePage()) + 1;
        self.sendRequest(self);
    }

    /**
     * Returns active products page number
     *
     * @returns {*}
     */
    Products.prototype.getActivePage = function () {
        return $('#' + this.options.paginationBlockID).data('active-page');
    }

    /**
     * Set pagination page as 1
     */
    Products.prototype.resetPage = function () {
        requestData.page = 1;
    }

    /**
     * Change category for shop page
     *
     * @param event
     * @param self
     */
    Products.prototype.changeCategory = function (event, self) {
        requestData.category = $(event.target).find('option:selected').val();
        self.resetPage();
        self.sendRequest(self);
    }

    Products.prototype.applyFilter = function (event, self) {
        requestData.orderBy = $(event.target).find('option:selected').val();
        self.resetPage();
        self.sendRequest(self);
    }

    Products.prototype.changeDestinationCountry = function (event, self) {
        event.preventDefault();
        requestData.destinationCountry = destinationCountrySelect.find('option:selected').val();
        self.sendRequest(self);
    }

    /**
     * Request products from the server
     */
    Products.prototype.sendRequest = function (self) {
        console.log(requestData);
        jQuery.ajax({
            type: 'POST',
            url: self.options.apiRoute,
            data: requestData,
            dataType: "json",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
            },
            success: function (response) {
                self.parseResponse(response, self);
            },
            error: function (data) {
                console.log(data);
            }
        });
    }

    Products.prototype.parseResponse = function (response) {
        console.log(response);

        if (!response.status) {
            console.log('Response error');
            return;
        }

        if (response.reload) { //Destination country changed
            window.location.reload();
            return;
        }

        $.each($('#shop-content .product-item'), function (key, value) {
            $(value).remove();
        });

        $('#shop-header').after(response.content);

        $('#shop-pagination').empty().append(response.pagination);

        $([document.documentElement, document.body]).animate({
            scrollTop: $("#shop-content").offset().top - 80
        }, 1000);
    }

    window.Products = Products;

})(jQuery);