(function ($) {
    let mapBlock;
    let map;
    let addressInput;
    let mapCategory;
    let autocomplete;
    let markers = [];
    let infoWindows = [];

    function GMap(options) {
        if (!options) {
            throw new Error('No options passed to Map constructor');
        } else if (!options.apiRoute) {
            throw new Error('No API route option passed to Map constructor');
        } else if (!options.nonce) {
            throw new Error('No Nonce option passed to Map constructor');
        } else if (!options.pathToTheme) {
            throw new Error('No path to theme option passed to Map constructor');
        }

        this.options = $.extend({}, GMap.defaults, options);
        this.setup();
    }

    /**
     * Prepare Google map
     */
    GMap.prototype.setup = function () {
        let $self = this;
        addressInput = document.getElementById("map-input");
        mapCategory = document.getElementById("map-category");
        mapBlock = document.getElementById('gmap');
        $self.eraseFields();

        let center = {lat: 51.507351, lng: -0.127758};

        map = new google.maps.Map(mapBlock, {
            center: center,
            zoom: 5
        });

        let defaultBounds = {
            north: center.lat + 0.1,
            south: center.lat - 0.1,
            east: center.lng + 0.1,
            west: center.lng - 0.1,
        };

        let options = {
            bounds: defaultBounds,
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            types: ['(regions)'],
        };
        autocomplete = new google.maps.places.Autocomplete(addressInput, options);
        $('#map-search-form').on('submit', function (event) {
            event.preventDefault();
            $self.search($self);
        });

        if (typeof preparedLocations !== 'undefined') {
            $.each(preparedLocations, function (key, location) {
                let position = {};
                position.lat = parseFloat(location.latitude);
                position.lng = parseFloat(location.longitude);
                $self.addMarker(position, location.type, location.infoWindow);
            });
        }

        google.maps.event.addListener(autocomplete, 'place_changed', function () {
            $self.changePlace();
        });
    };

    /**
     * Erase address input and category select if page reloaded
     */
    GMap.prototype.eraseFields = function () {
        addressInput.value = '';
        mapCategory.selectedIndex = 0;
    }

    /**
     * Set state and country from place required for search
     */
    GMap.prototype.changePlace = function () {
        try {
            let place = autocomplete.getPlace();
            let filtered_array = place.address_components.filter(function (address_component) {
                return address_component.types.includes("country");
            });
            let country = filtered_array.length ? filtered_array[0].long_name : "";
            console.log(country);

            filtered_array = place.address_components.filter(function (address_component) {
                return address_component.types.includes("administrative_area_level_1");
            });
            let state = filtered_array.length ? filtered_array[0].long_name : "";
            console.log(state);
            addressInput.dataset.country = country;
            addressInput.dataset.state = state;

        } catch (e) {
            console.log(e.message);
        }
    };

    /**
     * Search locations
     *
     * @param obj
     */
    GMap.prototype.search = function (obj) {
        event.preventDefault();

        let catId = mapCategory.options[mapCategory.selectedIndex].value;
        let country = addressInput.dataset.country;
        let state = addressInput.dataset.state;

        if (!addressInput.value) {
            country = state = '';
        }

        console.log(catId);
        console.log(country);
        console.log(state);

        var data = {
            'category_id': catId,
            'country': country,
            'state': state
        }

        obj.doSearchRequest(data, obj);
    }

    GMap.prototype.doSearchRequest = function (data, obj) {
        console.log(obj);
        jQuery.ajax({
            type: 'POST',
            url: obj.options.apiRoute,
            data: data,
            dataType: "json",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', obj.options.nonce);
            },
            success: function (response) {
                obj.parseResponse(response, obj);
            },
            error: function (data) {
                console.log(data);
            }
        });
    }

    /**
     * Parse search response
     *
     * @param response
     * @param obj
     */
    GMap.prototype.parseResponse = function (response, obj) {
        let $self = obj;

        console.log(response);
        if (response.status === 'error') {
            console.log('Search request error');
            return;
        }
        $self.deleteInfoWindows();
        $self.deleteMarkers(); //Delete old markers

        let mapSidebar = $('#map-sidebar');
        if (mapSidebar.length) {
            mapSidebar.empty();
        }

        let mapResultBlock = $('.map-result');
        if (mapResultBlock.length) {
            mapResultBlock.removeClass('cgs-hide');
        }

        if (response.locations.length) {
            $.each(response.locations, function (key, location) {
                let position = {};
                position.lat = parseFloat(location.latitude);
                position.lng = parseFloat(location.longitude);
                $self.addMarker(position, location.type, location.infoWindow);
                if (mapSidebar.length) {
                    $self.addSidebarBlock(location, mapSidebar);
                }
            });
        } else {
            console.log("Add empty results message");
            $self.addSidebarBlockWithEmptyResults($self.options.notFoundMapResultsMessage, mapSidebar);
        }
        $self.fitBounds();

        this.setMapOnAll(map);
    }

    /**
     * Add block with location data to sidebar
     *
     * @param location
     * @param mapSidebar
     */
    GMap.prototype.addSidebarBlock = function (location, mapSidebar) {
        let featuredClass = '';
        if (location.featured) {
            featuredClass = 'featured';
        }

        let block = '<div class="map-result-card ' + featuredClass + '">';
        block += '<div class="result-card-desc">';
        block += '<h4><a href="' + location.link + '">' + location.title + '</a></h4>';
        block += '<p>' + location.address + '</p>';
        block += '<div class="filter-icon">';
        $.each(location.iconData, function (key, value) {
            block += '<img src="' + value.url + '" alt="' + value.name + '">';
        });
        block += '</div>';
        block += '</div>';
        block += '<a href="' + location.link + '" class="btn btn-link btn-link-blue">Contact</a>';
        block += '</div>';
        mapSidebar.append(block);
    }

    /**
     * Add block with "no results" message to sidebar
     *
     * @param message
     * @param mapSidebar
     */
    GMap.prototype.addSidebarBlockWithEmptyResults = function (message, mapSidebar) {
        let block = '<div class="map-result-card">';
        block += '<div class="result-card-desc">';
        block += '<p>' + message + '</p>'
        block += '</div></div>';
        mapSidebar.append(block);
    }

    /**
     * Create marker
     *
     * @param position
     * @param type
     * @param infoWindowContent
     */
    GMap.prototype.addMarker = function (position, type, infoWindowContent) {
        let $self = this;

        let imageIcon;
        switch (type) {
            case 'cygnus-offices':
                imageIcon = this.options.pathToTheme + '/images/map/cygnus-offices.svg';
                break;
            case 'distributors':
                imageIcon = this.options.pathToTheme + '/images/map/distributors.svg';
                break;
            case 'service-centres':
                imageIcon = this.options.pathToTheme + '/images/map/service-centers.svg';
                break;
            default:
                imageIcon = this.options.pathToTheme + '/images/map/cygnus-offices.svg';
                break;
        }

        let infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent,
        });

        let marker = new google.maps.Marker({
            position,
            map,
            icon: imageIcon
        });

        marker.addListener("click", () => {
            $self.closeInfoWindows();
            infoWindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
            });
        });

        infoWindows.push(infoWindow);
        markers.push(marker);
    }

    /**
     * Add markers on the map
     *
     * @param map
     */
    GMap.prototype.setMapOnAll = function (map) {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }

    /**
     * Set the borders of the map on markers
     */
    GMap.prototype.fitBounds = function () {
        if (markers.length) {
            let bounds = new google.maps.LatLngBounds();
            let i;
            for (i = 0; i < markers.length; i++) {
                bounds.extend(new google.maps.LatLng(markers[i].getPosition().lat(), markers[i].getPosition().lng()));
            }
            map.fitBounds(bounds);
            if (i === 1) {
                map.setZoom(9);
            }
        }
    }

    /**
     * Hide all markers
     */
    GMap.prototype.hideMarkers = function () {
        this.setMapOnAll(null);
    }

    /**
     * Show all markers
     */
    GMap.prototype.showMarkers = function () {
        this.setMapOnAll(map);
    }

    /**
     * Delete all markers
     */
    GMap.prototype.deleteMarkers = function () {
        this.hideMarkers();
        markers = [];
    }

    GMap.prototype.closeInfoWindows = function () {
        for (let i = 0; i < infoWindows.length; i++) {
            infoWindows[i].close();
        }
    }

    GMap.prototype.deleteInfoWindows = function () {
        infoWindows = [];
    }

    window.GMap = GMap;

})(jQuery);